<template>
    <div class="footer">
        <div
            class="footer-text"
            v-html="content"
        />
    </div>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {
            content: "",
        };
    },
    mounted () {
        import(
            /* webpackChunkName: "footer-text-index" */ "@/texts/footer.js"
        ).then((res) => {
            this.content = res.default.content;
        });
    },
}
</script>

<style class="css">
  .footer__wrapper {
    border-top: 1px solid #d9d9d9;
  }

  .footer {
    max-width: 862px;
    padding-top: 40px;
    padding-bottom: 60px;
  }

  .footer-text p {
    font-size: 10px;
    line-height: 14px;
    font-weight: 300;
    margin-bottom: 1rem;
  }
</style>